.main {
  gap: 4px;

  .label {
    color: var(--Neutral-N200);
  }

  .input {
    width: 100%;
    padding: 8px 6px;
    border: 2px solid var(--Neutral-N40);
    border-radius: 3px;
    background: var(--Neutral-N10);
    color: var(--Neutral-N900);
    outline: none;

    ::placeholder {
      color: var(--Neutral-N100);
    }

    &:focus {
      border-color: var(--Blue-B100);
    }

    &.error {
      border-color:  var(--Red-R400);
    }

    &:disabled {
      border-color: var(--Neutral-N10);
      background-color: var(--Neutral-N10);
    }
  }

  textarea {
    resize: none;

    &.resize {
      resize: initial;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      box-shadow: 0 0 0 1000px var(--Neutral-N10) inset !important;
      -webkit-text-fill-color: var(--Neutral-N900) !important;
      transition: background-color 5000s ease-in-out 0s;
  }
}

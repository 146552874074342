:root {
  // Neutral
  --Neutral-N0: #090A0B;
  --Neutral-N10: #101213;
  --Neutral-N20: #131516;
  --Neutral-N30: #1A1B1C;
  --Neutral-N40: #202123;
  --Neutral-N80: #414345;
  --Neutral-N90: #56585B;
  --Neutral-N100: #6B6D71;
  --Neutral-N200: #777A7D;
  --Neutral-N300: #86898D;
  --Neutral-N400: #8E9195;
  --Neutral-N500: #9DA0A4;
  --Neutral-N700: #BCBFC5;
  --Neutral-N800: #D9DDE3;
  --Neutral-N900: #E3E3E3;
  --neutral-alpha-n-20-a: #D9DDE324;
  --neutral-alpha-n-100-a: #D9DDE3BA;
  --neutral-alpha-n-800-a: #D9DDE3F7;

  // Blue
  --Blue-B75: #C0B6F2;
  --Blue-B100: #998DD9;
  --Blue-B200: #8777D9;
  --Blue-B400: #5243AA;
  --Blue-B500: #403294;

  // Purple
  --Purple-P75: #C0B6F2;
  --Purple-P100: #998DD9;
  --Purple-P400: #5243AA;
  --Purple-P500: #403294;

  // Red
  --Red-R200: #FF7452;
  --Red-R300: #FF5630;
  --Red-R400: #DE350B;

  // Secondary-Sticky-Header
  --Secondary-Sticky-Header: #131516CC;

  // Secondary-Orange
  --Secondary-Orange-O75: #F9CF99;
  --Secondary-Orange-O300: #F18701;
  --Secondary-Orange-O600: #603600;

  // Secondary-Pink-Sakura
  --Secondary-Pink-Sakura-S75: #EDAEBD;
  --Secondary-Pink-Sakura-S200: #DA5D7C;
  --Secondary-Pink-Sakura-S300: #D1345B;
  --Secondary-Pink-Sakura-S400: #A72A49;
  --Secondary-Pink-Sakura-S500: #7D1F37;
  --Secondary-Pink-Sakura-S700: #3F101B;

  // Secondary-Emerald
  --Secondary-Emerald-E25: #8FF4D2;
  --Secondary-Emerald-E50: #67C4A4;
  --Secondary-Emerald-E100: #3E9377;
  --Secondary-Emerald-E200: #237358;
  --Secondary-Emerald-E300: #08533A;
  --Secondary-Emerald-E400: #06422E;
  --Secondary-Emerald-E500: #053223;
  --Secondary-Emerald-E600: #032117;
  --Secondary-Emerald-E700: #021911;
}

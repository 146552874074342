@import '../../styles/constants';

$FOOTER_PADDING: 20px;
$FOOTER_PADDING_DESKTOP: 52px;

.main {
  width: 100%;

  .inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .logo {
    display: flex;
    height: $BRANDBAR_LOGO_SIZE;
    align-items: center;
    padding-left: 32px;
    background-image: url('../../../public/assets/logo.png');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: auto $BRANDBAR_LOGO_SIZE;
    color: var(--Neutral-N300);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-decoration: none;
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      margin-top: 0;
      list-style: none;
    }
  }

  &.header {
    position: fixed;
    z-index: $Z_INDEX_HEADER;
    top: 0;
    left: 0;
    padding: $BRANDBAR_V_PADDING 0;
    backdrop-filter: blur(24px);
    background-color: var(--Secondary-Sticky-Header);
  }

  &.footer {
    padding: ($FOOTER_PADDING + $SECTION_MARGIN_TOP) 0 $FOOTER_PADDING;
    margin-top: auto;
    background-color: var(--Neutral-N0);
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  .main {
    .inner {
      flex-direction: row;
      gap: 0;
    }

    .links {
      margin-left: auto;
      gap: 80px;
    }
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  .main {
    &.footer {
      padding: ($FOOTER_PADDING_DESKTOP + $SECTION_MARGIN_TOP_DESKTOP) 0 $FOOTER_PADDING_DESKTOP;
    }
  }
}

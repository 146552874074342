@import '../../styles/constants';

$BENEFITS_CARD_GAP: 20px;
$BENEFITS_CARD_WIDTH: 530px;
$BENEFITS_CARD_HEIGHT: 304px;

.main {
  .heading {
    text-align: center;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    gap: $BENEFITS_CARD_GAP;
  }

  .wrapper {
    display: inline-flex;
    width: 100%;
    height: $BENEFITS_CARD_HEIGHT;
    flex-grow: 1;
    padding: 24px;
    border-radius: 16px;
  }

  .item {
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: auto 175px;
    gap: 16px;

    .svg {
      position: absolute;
      top: 0;
      right: 0;
      transform: scale(0.8);
      transform-origin: right top;
    }
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  .main {
    .item {
      .svg {
        transform: scale(1);
      }
    }
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    .wrapper {
      max-width: calc(50% - $BENEFITS_CARD_GAP / 2);
    }
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  .main {
    .wrapper {
      width: $BENEFITS_CARD_WIDTH;
    }
  }
}

@import '../../../../styles/constants';

$CONTACT_FORM_IMAGE_WIDTH: 542px;

.main {
  .content {
    margin-top: 152px;
  }

  .image {
    display: none;
  }

  .email {
    margin-top: 24px;

    .link {
      margin-left: 4px;
      color: var(--Blue-B200);
    }
  }

  .form {
    width: 100%;

    .fieldset {
      margin-top: 32px;
      gap: 24px;
    }

    .submit {
      align-self: flex-end;
      margin-top: 24px;
    }
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    .content {
      display: flex;
      border-radius: 12px;
      margin-top: 120px;
      background-color: var(--Neutral-N20);
    }

    .image {
      display: block;
      width: $CONTACT_FORM_IMAGE_WIDTH;
      object-fit: cover;
    }

    .form {
      margin: 32px 24px 0;
    }
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  .main {
    .form {
      margin: 48px 32px 0;

      .fieldset {
        margin-top: 40px;
      }

      .submit {
        margin-top: 32px;
      }
    }
  }
}

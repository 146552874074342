.main {
  .content {
    margin: 0 auto;
  }

  .heading {
    text-align: center;
  }

  .description {
    margin-top: 36px;
    text-align: center;
  }

  .button {
    margin-top: 32px;
  }
}

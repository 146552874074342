@import '../../styles/constants';

.main {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 510;
  line-height: 20px;
  outline: none;
  text-align: center;
  text-decoration: none !important;

  &.primary {
    padding: 10px 18px;
    border: 2px solid var(--Blue-B100);
    border-radius: 33px;
    color: var(--Neutral-N900);

    &:hover {
      background-color: var(--Blue-B100);
      color: var(--Neutral-N10);
    }

    &:active, &:focus {
      border-color: var(--Blue-B200);
      background-color: var(--Blue-B200);
      color: var(--Neutral-N10);
    }

    &:disabled {
      &, &:hover, &:active, &:focus, &.active {
        border-color: var(--Neutral-N400);
        background-color: transparent;
        color: var(--Neutral-N400);
        cursor: default;
      }
    }
  }

  &.secondary {
    padding: 12px 20px;
    border-radius: 99999px;
    background-color: var(--neutral-alpha-n-800-a);
    color: var(--Neutral-N0);

    &:hover {
      background-color: var(--neutral-alpha-n-100-a);
    }

    &:active, &:focus {
      background-color: var(--Neutral-N700);
    }

    &:disabled {
      &, &:hover, &:active, &:focus, &.active {
        background-color: var(--neutral-alpha-n-20-a);
        color: var(--Neutral-N90);
        cursor: default;
      }
    }
  }

  &.tetriary {
    padding: 0;
    color: var(--Neutral-N100);

    &:hover {
      color: var(--Purple-P100);
    }

    &:active, &:focus, &.active {
      color: var(--Neutral-N900);
    }

    &:disabled {
      &, &:hover, &:active, &:focus, &.active {
        color: var(--Neutral-N90);
        cursor: default;
      }
    }
  }

  &.wide {
    width: 100%;
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  .main {
    &.wide {
      width: 50%;
      margin: 0 auto;
    }
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    &.wide {
      width: initial;
      margin: initial;
    }
  }
}

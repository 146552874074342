@import '../../../../styles/constants';

$FEATURES_CARD_WIDTH: 308px;
$FEATURES_CARD_WIDTH_BIG: 348px;
$FEATURES_CARD_HEIGHT: 400px;
$FEATURES_CARD_HEIGHT_BIG: 408px;

.main {
  overflow: hidden;
  width: 100%;
  max-width: 100%;

  .heading {
    margin: 0 auto 40px;
    text-align: center;
  }

  .card {
    max-width: $FEATURES_CARD_WIDTH;
    height: $FEATURES_CARD_HEIGHT;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 24px;
    border-radius: 16px;
    background: var(--Neutral-N20);
    gap: 16px;
  }

  .icon {
    width: 90px;
    height: 90px;
    margin-bottom: auto;
  }

  .description {
    color: var(--Neutral-N300);
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  .main {
    .card {
      max-width: $FEATURES_CARD_WIDTH_BIG;
      height: $FEATURES_CARD_HEIGHT_BIG;
      gap: 20px;
    }
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    .card {
      max-width: $FEATURES_CARD_WIDTH;
      height: $FEATURES_CARD_HEIGHT;
    }
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  .main {
    .card {
      max-width: $FEATURES_CARD_WIDTH_BIG;
      height: $FEATURES_CARD_HEIGHT_BIG;
    }
  }
}

@import './toast';
@import './colors';
@import './swiper';
@import './animation';
@import './constants';
@import './typography';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: var(--Neutral-N0);
  color: var(--Neutral-N900);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table {
  padding: 0;
  margin: 0;
}

a, button {
  &:focus-visible {
    outline: var(--Blue-B100) solid 1px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

fieldset {
  padding: 0;
  border: none;
  margin: 0;
}

#root {
  height: 100%;
}

.layout {
  max-width: $MOBILE_BREAKPOINT;
  padding: 0;
  margin: 0 auto;
}

.container {
  padding: 0 20px;
}

.d-flex {
  display: flex;
  flex-direction: column;
}

.d-flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.t-center {
  text-align: center;
}

.section {
  margin-top: $SECTION_MARGIN_TOP;
}

.narrow-content {
  max-width: $NARROW_CONTENT_WIDTH;
}

.svg-current-color {
  svg {
    path {
      /* stylelint-disable-next-line value-keyword-case */
      fill: currentColor;
    }
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  .layout {
    max-width: $DESKTOP_BREAKPOINT;
  }

  .container {
    padding: 0 36px;
  }
}

@media (min-width: $DESKTOP_BREAKPOINT) {
  .container {
    padding: 0 100px;
  }

  .section {
    margin-top: $SECTION_MARGIN_TOP_DESKTOP;
  }
}

@import '../../styles/constants';

.main {
  margin-top: 152px;
}

@media (min-width: $TABLET_BREAKPOINT) {
  .main {
    margin-top: 120px;
  }
}

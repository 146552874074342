$DESKTOP_BREAKPOINT: 1280px;
$LAPTOP_BREAKPOINT: 1024px;
$TABLET_BREAKPOINT: 768px;
$MOBILE_BREAKPOINT: 575px;

$SECTION_MARGIN_TOP: 80px;
$SECTION_MARGIN_TOP_DESKTOP: 134px;

$NARROW_CONTENT_WIDTH: 716px;

$Z_INDEX_HEADER: 999;

$DESKTOP_H1_LINE_HEIGHT: 56px;
$TEXT1_LINE_HEIGHT: 32px;

$BRANDBAR_LOGO_SIZE: 24px;
$BRANDBAR_V_PADDING: 20px;
$HEADER_HEIGHT: calc($BRANDBAR_LOGO_SIZE + 2 * $BRANDBAR_V_PADDING);

.main {
  position: relative;
  display: grid;
  border-radius: 999px;
  background: var(--Neutral-N30);
  grid-template-columns: 1fr 1fr;

  .background {
    position: absolute;
    z-index: 2;
    top: 0;
    display: block;
    width: 50%;
    height: 100%;
    border-radius: 999px;
    background: var(--Neutral-N80);
    cursor: pointer;
    transition: 0.1s ease left;
    will-change: left;

    &.left {
      left: 0;
    }

    &.right {
      left: 50%;
    }
  }

  .item {
    z-index: 3;
    display: inline-block;
    width: 100%;
    padding: 12px 20px;
    border: none;
    border-radius: 999px;
    background: transparent;
    color: var(--Neutral-N900);
    cursor: pointer;
    text-align: center;
    transition: 0.3s ease background-color;
    will-change: background-color;

    .badge {
      position: absolute;
      z-index: 4;
      top: -10px;
    }

    &:hover {
      color: var(--Purple-P75);
    }

    &:active {
      color: var(--Purple-P100);
    }

    &:disabled {
      color: var(--Neutral-N100);
    }

    &.active {
      &, &:hover, &:active {
        color: var(--Neutral-N900);
      }
    }

    &.left .badge {
      left: 0;
    }

    &.right .badge {
      right: 0;
    }
  }

  .background,
  .item.active {
    &:hover {
      background-color: var(--Purple-P400);
    }

    &:active {
      background-color: var(--Purple-P500);
    }

    &.disabled {
      background-color: var(--Neutral-N60);
    }
  }
}

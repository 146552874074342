@import '../../styles/constants';

.main {
  animation: show-header 1s ease-in-out forwards;
  transform: translateY(-$HEADER_HEIGHT);
  will-change: transform;
}

@keyframes show-header {
  to {
    transform: translateY(0);
  }
}

@import '../../../../styles/constants';

.main {
  margin-top: 24px;

  .item {
    &:not(:first-child) {
      margin-top: $SECTION_MARGIN_TOP;
    }
  }

  .title,
  .caption {
    text-align: center;
  }

  .image {
    width: 100%;
    max-width: 360px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .caption {
    color: var(--Neutral-N300);
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    margin-top: 110px;

    .item {
      &:not(:first-child) {
        margin-top: $SECTION_MARGIN_TOP_DESKTOP;
      }
    }

    .image {
      max-width: 100%;
    }
  }
}

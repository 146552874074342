@import '../../styles/constants';

.main {
  .switcher {
    margin-top: 48px;
  }

  .swiper {
    max-width: 100vw;
  }

  .list {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    margin-top: 64px;
    gap: 20px;
  }

  .item {
    flex-grow: 1;
    padding: 24px;
    border-radius: 12px;
    background: var(--Neutral-N20);

    &.plus {
      border: 2px solid var(--Blue-B100);
    }
  }

  .cost {
    display: flex;
    height: 44px;
    align-items: baseline;
    align-self: stretch;
    margin-top: 12px;
    gap: 4px;
  }

  .price,
  .term,
  .li {
    color: var(--Neutral-N300);
  }

  .price {
    text-decoration: line-through;
  }

  .description {
    margin-top: 20px;
  }

  .package,
  .button {
    margin-top: 48px;
  }

  .package {
    margin-bottom: auto;
  }

  .li {
    display: flex;
    margin-top: 14px;

    &::before {
      display: inline-flex;
      width: 24px;
      height: 24px;
      background-image: url('../../../public/assets/check.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
    }
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    .list {
      flex-direction: row;
    }

    .item {
      width: calc(100% / 3);
    }
  }
}
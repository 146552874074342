@import './constants';

.pricing-swiper,
.enterprise-swiper {
  width: calc(100vw - 2 * 20px);
}

.pricing-swiper {
  max-width: $MOBILE_BREAKPOINT;
}

@media (min-width: $MOBILE_BREAKPOINT) {
  .pricing-swiper,
  .enterprise-swiper {
    width: calc(100vw - 2 * 36px);
  }
}

@media (min-width: $TABLET_BREAKPOINT) {
  .pricing-swiper,
  .enterprise-swiper {
    max-width: 100vw;
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .pricing-swiper,
  .enterprise-swiper {
    width: initial;
  }

  .enterprise-swiper {
    .swiper-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

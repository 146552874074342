@import '../../styles/constants';

$HERO_HEIGHT_DESKTOP: 572px;
$HERO_CONTENT_WIDTH: 532px;
$HERO_KEYHOLE_SIZE: 40px;

.main {
  position: relative;
  margin-top: 152px;

  .content {
    height: 100%;
    justify-content: flex-end;
    gap: 24px;

    .title,
    .subtitle,
    .description,
    .button {
      text-align: center;
    }

    .title {
      // height: calc(2 * $DESKTOP_H1_LINE_HEIGHT);
    }

    .description {
      // height: calc(4 * $TEXT1_LINE_HEIGHT);
      color: var(--Neutral-N300);
    }

    .button {
      margin-bottom: 16px;
    }
  }

  .image {
    width: 100%;
    margin-top: 58px;
  }
}

@media (min-width: $LAPTOP_BREAKPOINT) {
  .main {
    height: $HERO_HEIGHT_DESKTOP;
    flex-direction: column;
    margin-top: 0;
    background: url('../../../public/assets/hero-desktop.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    gap: 0;

    .content {
      width: $HERO_CONTENT_WIDTH;
      gap: 28px;

      .title,
      .subtitle,
      .description,
      .button {
        text-align: left;
      }
    }

    .image {
      display: none;
    }

    .cursor {
      position: absolute;
      right: 250px;
      bottom: 135px;
      width: 35px;
      height: 35px;
      cursor: not-allowed;
    }
  }
}
